import React from 'react'
import Logo from '../assets/Logo-clear.png'
import {payImage, contactInfo, quickLinks} from  '../data'
import {ImLocation} from 'react-icons/im'
import footerWave from '../assets/footer-wave.png'
import {Link} from 'react-router-dom';







const Footer = () => {
  return (
    <>
    <img src={footerWave} className="bg-[#e5f6f5] w-full" />
      <div className='bg-[#1e3633] flex flex-col lg:flex-row justify-between '>
        <div className=' lg:w-2/5 2xl:w-2/4'>
          <img src={Logo} className='w-[20rem]' alt="Logo" />
          <p className='lg:w-2/3 2xl:w-2/6 mt-[-20%] 2xl:mt-[-10%] text-[#bcc0bf]  px-16'>You can trust Maria & Stan Cleaning for reliable and punctual home cleaning services. Same day appointments are often available</p>
          <div className='flex  px-12'>
          {payImage.map((item, index) => {
              const {image, id} = item;
              return (
                  <div className=''>
                      <div className='' key={id}>
                          <img src={image} className='ml-3 px-2 w-[3.3rem] cursor-pointer' alt="pay images" />
                      </div>
                  </div>
              )
          })}
          </div>
        </div>
        <div className='flex flex-col lg:flex-row px-16 py-28 gap-x-16'>
        <div className='text-white'>
          <h2 className='text-[1.3rem]'>Quick Links</h2>
          <ul className='py-2 ml-[-3rem]'>
            {quickLinks.map((link, index) => {
              const {name, url, id} = link;
              return (
                <li key='index' className='pt-2'><span className='text-red-400 text-lg pr-2'>.</span>
                  <Link className='no-underline quick-link text-white hover:text-red-400' to={url}>
                    {name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='text-white'>
          <h2 className='text-[1.3rem]'>Contact Info</h2>

          {contactInfo.map((contact, index) => {
              const {icon, title, href, subtitle} = contact;
              return (
                  <div className='flex flex-row gap-x-3 group pt-8 mb-[-10%]'>
                  <div className='bg-[#182c29] w-[2.5em] h-[2.5em]  rounded-full'>
                      <div className='text-[1rem] px-[28%] group-hover:text-red-400 group ml-[1px] py-[28%]'>{icon}</div>
                  </div>
                  <div>
                      <h3 className='text-sm group group-hover:text-red-400 font-normal'>{title}</h3>
                      <a className='no-underline ' target='_blank' href={href}><p className='text-[#bcc0bf] group-hover:text-red-400 text-sm'>{subtitle}</p></a>
                  </div>
              </div>
              )
          })}
        </div>
      </div>
    </div>
    </>
  )
}

export default Footer
