import React, { useEffect } from 'react'
import constructionImg from '../assets/construction.jpg'
import {AiOutlineMail} from 'react-icons/ai'


const Commercial = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='bg-gray-200'>
        <div>
            <img src={constructionImg}  className='w-full h-[100vh] brightness-90 object-cover' alt="image" />
            <div 
            data-aos='fade-up'
            data-aos-delay='200'
            className='absolute text-center top-[45%] lg:top-[41%] left-[19%] lg:left-[34%] 2xl:left-[42%] dancing lg:w-fit -translate-x-1/2'>
                <p className='lg:text-[4rem]  text-white'>Construction Cleaning</p>
            </div>
        </div>
        <div 
        data-aos='fade-down'
        data-aos-delay='100'
        className='py-4'>
            <h2 className='text-center text-[#248275] text-[3.5rem] font-[600]'>Construction Cleanup Services</h2>
            <div className='flex justify-center px-4 lg:gap-4 py-12 flex-col lg:flex-row'>
                <p className='w-full lg:w-2/5'>Maria & Stan Cleaning Services offers construction clean-up services at reasonable prices. We have a team of construction cleaning professionals who can quickly and thoroughly clean your newly constructed homes right on the construction site. We also offer clean up of homes that are undergoing extensive remodels. We offer extensive deep cleaning that is designed to put the finishing touches on your new or newly remodeled home.</p>
                <p className='w-full lg:w-2/5'>Let the experts at M&S Cleaning Service help you with all of your construction cleaning needs. We know that getting your work-site cleaned should be done consistently and in a timely matter. Give M&S Cleaning Service a chance to clean your home today.</p>
            </div>
        </div> 
        <div 
        data-aos='fade-up'
        data-aos-delay='100'
        className='pb-16'>
            <p className='dancing mt-4 text-red-400 text-center'>Services</p>
            <h2 className='text-center px-4 text-[#248275] font-bold text-[1rem]'>Contact M&S Cleaning Services today so we can take care of your construction cleaning service needs.</h2>
            <a href='mailto:mariaandstancleaning@gmail.com'className='rounded-[120px] py-2 mt-16 lg:py-8 m-auto capitalize px-2 bg-red-400 hover:scale-105  flex flex-row w-max text-md lg:text-lg no-underline'>
                    <div className='lg:w-[4rem] lg:h-[4rem]   bg-white rounded-full'>
                    <AiOutlineMail className='text-[#f96666] group-hover:text-white text-2xl lg:text-[2rem] m-3'/>
                    </div> 
                    <span className='text-center text-white m-auto px-8 no-underline  lg:text-2xl'>email us now</span>
                </a>
        </div>
    </div>
  )
}

export default Commercial
