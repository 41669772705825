import React, { useEffect } from 'react'
import HeroSlider from './HeroSlider'
import servicesImage from '../assets/hero-border-top.png'
import Intro from './Intro'
import Services from './Services'
import Welcome from './Welcome'
import DogSection from './DogSection'



const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className='overflow-hidden'>
      <section className='relative flex  items-center overflow-hidden '>
        <HeroSlider />
      </section>
      <Intro />
      <Services />
      <Welcome />
      <DogSection />
    </div>
  )
}

export default Home
