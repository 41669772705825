import React, {useState, useEffect} from  'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'
import Home from './components/Home'
import House from './pages/House'
import Commercial from './pages/Commercial'
import Construction from './pages/Construction'
import About from './pages/About'
import Contact from './pages/Contact'
import Footer from './components/Footer'
import 'aos/dist/aos.css'
import Aos from 'aos';







function App() {
    //mobile nav state
    const [navMobile, setNavMobile] = useState(false);
    //aos init
    useEffect(() => {
      Aos.init({
        duration: 2500, delay: 200
      });
    }); 
  return (
    <Router>
      <Header />
      <Routes >
        <Route element={<Home />} path='/' exact></Route>
      </Routes>
      <Routes >
        <Route element={<House />} path='/house' exact></Route>
      </Routes>
      <Routes >
        <Route element={<Commercial />} path='/commercial' exact></Route>
      </Routes>
      <Routes >
        <Route element={<Construction />} path='/construction' exact></Route>
      </Routes>
      <Routes >
        <Route element={<About />} path='/about' exact></Route>
      </Routes>
      <Routes >
        <Route element={<Contact />} path='/contact' exact></Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
