import React from 'react'
import {Swiper, SwiperSlide,} from 'swiper/react'
import { Pagination, Navigation } from "swiper";
import "swiper/css/pagination";
import 'swiper/css'
import {Link} from 'react-router-dom';
import '../swiper.css'
import {servicesSlider,} from '../data';

const Services = () => {
  return (
<div className='z-[-10] bg-transparent'>
<p 
    data-aos='fade-down'
    className='dancing mt-4 text-red-400 text-center'>Services</p>
<h2 
    data-aos='fade-down'
    data-aos-delay='200'
    className='text-center px-2 text-[3rem] lg:text-[3.5rem] text-[#248275] font-bold'>Give Your Home A Deep Clean</h2>
      <Swiper
         breakpoints={{
    768: {
      width: 768,
      slidesPerView: 1,
    },
    1020: {
      width: 1020,
      slidesPerView: 2,
    },
  }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        data-aos='fade-down'
        data-aos-delay='200'
        className="w-full heroSlider overflow-y-hidden gap-2 h-full"
      >
        {servicesSlider.map((item, index) => {
          const {image, title} = item;
          return (
              <SwiperSlide 
            key={index}
            className='text-center  py-8 px-8 cursor-pointer group pb-12 text-[18px]'>
              <div className='bg-[#e5f6f5] w-full md:w-[23rem] h-max rounded-lg m-auto'>
                <div className='text-center align-middle m-auto h-[12rem]'>
                  <img src={image} alt="image" className='rounded-md  h-full' />
                </div>
                <div className='py-8 capitalize tracking-wide text-[#248275] group-hover:text-[#7bdcb5] text-3xl font-bold'>{title}</div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className='text-center py-4'>
        <Link to='/house'>
          <button className='rounded-[120px] capitalize py-[15px] px-[35px] border-solid border-[2px] hover:text-white border-red-400 text-red-400 hover:bg-solid hover:bg-red-400 delay-100'>view more services</button>
        </Link>
      </div>
    </div>
  )
}

export default Services

