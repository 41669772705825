import React, { useEffect } from 'react'
import commercialImg from '../assets/commercial.jpg'
import {AiOutlineMail} from 'react-icons/ai'


const Commercial = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='bg-gray-200'>
        <div>
            <img src={commercialImg}  className='w-full h-[100vh] brightness-90 object-cover' alt="image" />
            <div 
                data-aos='fade-down'
                data-aos-delay='200'
                className='absolute text-center top-[46%] lg:top-[40%] left-[21%] lg:left-[34%] 2xl:left-[40%] dancing lg:w-fit -translate-x-1/2'>
                <p className='lg:text-[4rem]  text-white'>Commercial Cleaning</p>
            </div>
        </div>
        <div className='py-4'>
            <h2
            data-aos='fade-up'
            data-aos-delay='200'
            className='text-center text-[#248275] text-[3.5rem] font-[600]'>Commercial Cleanup Services</h2>
            <div 
            data-aos='fade-up'
            data-aos-delay='300'
            className='flex justify-center px-4 lg:gap-4 py-12 texl-lg xl:text-xl flex-col lg:flex-row'>                
                <p className='w-full lg:w-2/5'>Maria & Stan Cleaning Services offers one-time and ongoing commercial cleaning services at a reasonable price, whether you decide to have us come out daily or once a month. We have a team of cleaning professionals who can quickly and thoroughly clean your office, building or workplace. We offer extensive deep cleaning that is designed to make your workplace feel like it is brand new again.</p>
                <p className='w-full lg:w-2/5'>Let the experts at M&S Cleaning Service help you with all of your commercial cleaning needs. We know that getting your workplace cleaned should be done consistently and in a timely matter. Give M&S Cleaning Service a chance to clean your home today.</p>
            </div>
        </div> 
        <div 
        data-aos='fade-down'
        data-aos-delay='100'
        className='pb-16 w-3/4 2xl:w-2/4 m-auto'>
            <p className='dancing mt-4 text-red-400 text-center'>Services</p>
            <h2 className='text-center px-4 text-[#248275] font-bold text-[1.5rem] 2xl:text-[2rem]'>Contact M&S Cleaning Services today so we can take care of your commercial cleaning service needs.</h2>
            <a href='mailto:mariaandstancleaning@gmail.com'className='rounded-[120px] py-2 mt-16 lg:py-8 m-auto capitalize px-2 bg-red-400 hover:scale-105  flex flex-row w-max text-md lg:text-lg no-underline'>
                    <div className='lg:w-[4rem] lg:h-[4rem]   bg-white rounded-full'>
                    <AiOutlineMail className='text-[#f96666] group-hover:text-white text-2xl lg:text-[2rem] m-3'/>
                    </div> 
                    <span className='text-center text-white m-auto px-8 no-underline  lg:text-2xl'>email us now</span>
                </a>
        </div>
    </div>
  )
}

export default Commercial
