import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import {Link} from 'react-router-dom';
import {links} from '../data'
import Logo from '../assets/Logo.png'


const NavBar = () => {
  const [nav, setNav] = useState(false);
  return (
    <div className="flex justify-between items-center w-full h-20 px-8 text-[#248275] bg-white z-[1000] fixed">
      <Link to='/' className="w-2/5   2xl:px-12  2xl:py-12  lg:w-[15%]">
        <img src={Logo} className="" alt="" />
      </Link>

      <ul className="hidden md:flex">
        {links.map(({ id, link, title }) => (
          <li
            key={id}
            className="px-4 cursor-pointer capitalize font-medium hover:scale-105 duration-200"
          >
            <Link to={link} className='no-underline text-blue-500' smooth duration={500}>
              {title}
            </Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNav(!nav)}
        className="cursor-pointer pr-4 z-10  md:hidden"
      >
        {nav ? <FaTimes className="text-white" size={30} /> : <FaBars className="text-blue-400" size={30} />}
      </div>

      {nav && (
        <ul className="flex flex-col justify-center items-center  absolute top-0 left-0 w-full h-screen bg-[#36d0c4] text-gray-500">
          {links.map(({ id, link, title }) => (
            <li
              key={id}
              className="px-4 cursor-pointer capitalize py-6 text-4xl"
            >
              <Link
                onClick={() => setNav(!nav)}
                to={link}
                duration={500}
                className='no-underline text-white'
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBar;
