import React from 'react'
import {welcomeInfo} from '../data';
import {Link} from 'react-router-dom';
import welcomeTop from '../assets/welcome-top.png'


const Welcome = () => {
  return (
    <div>
        <div className='w-full'>
            <img src={welcomeTop} className='w-full' alt="image" />
        </div>
        {welcomeInfo.map((item, index) => {
            const {image, name, title, paragraph, paragraph2} = item;
            return (
                <div className='flex flex-col lg:flex-row pb-20  bg-[#e5f6f5]  px-12 py-12'>
                    <div
                        data-aos='fade-left'
                        data-aos-delay='200'
                     className=' lg:w-full 2xl:w-3/4'>
                        <img src={image} className='lg:w-full ' alt="image" />
                    </div>
                    <div 
                        data-aos='fade-right'
                        data-aos-delay='200'
                        className='w-5/6 px-8 py-8'>
                        <h3 className='dancing text-red-400  tracking-wide'>{name}</h3>
                        <h2 className='text-2xl lg:text-[3rem] xl:text-[4.2rem] font-bold leading-none lg:w-[80%] xl:w-[90%] text-[#248275]'>{title}</h2>
                        <div className='text-[1.3rem] 2xl:text-[2rem]'>
                            <p className='lg:w-[85%] 2xl:w-[60%] pt-2'>{paragraph}</p>
                            <p className='lg:w-[85%] 2xl:w-[60%]'>{paragraph2}</p>
                        </div>
                        <div className='py-2 px-4'>
                            <Link to='commercial' className='!text-white xl:text-[1rem] 2xl:text-[1.5rem]'>
                                <button className='rounded-[120px]  capitalize py-[15px] px-[35px] border-solid border-[2px] hover:border-[#36d0c4] hover:text-[#36d0c4] hover:bg-solid bg-[#36d0c4] hover:bg-transparent mr-4 mb-2  delay-100'>Commercial</button>
                            </Link>
                            <Link to='construction' className='!text-white xl:text-[1rem] 2xl:text-[1.5rem]'>
                                <button className='rounded-[120px]  capitalize py-[15px] px-[35px] border-solid border-[2px]  hover:text-red-400 hover:border-red-400 hover:border-solid bg-solid hover:bg-transparent bg-red-400 delay-100'>Construction</button>
                            </Link>
                        </div>
                    </div>

                </div>
            )
        })}
    </div>
  )
}

export default Welcome
