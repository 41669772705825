import React from 'react'
import DogBg from '../assets/dog-bg.jpg'
import {AiOutlineMail} from 'react-icons/ai'
import bottomImg from '../assets/dog-section-bottom.png'


const DogSection = () => {
  return (
    <div>
        <div className='relative'>
            <img src={DogBg} className='h-[120vh] z-[-10000] object-cover' alt="" />
            <div
                data-aos='fade-left'
                data-aos-delay='200'
            className='absolute text-left top-12 lg:top-[20%] px-8 lg:px-36 w-[70%]  lg:h-[100%]'>
                <h4 className='dancing  text-white w-3/4'>Maria & Stan Cleaning</h4>
                <h2 className='text-white text-[3rem] lg:text-[6rem] lg:font-bold'>One Call Cleans All</h2>
                <p className='text-white text- w-2/3 lg:text-lg'>We Clean Homes, Condominiums, Apartments, Offices, New Model Homes and Under Construction Homes, and Remodeling Projects.</p>
                <a href='mailto:mariaandstancleaning@gmail.com'className='rounded-[120px] py-2 lg:py-[15px] capitalize px-2 bg-red-400 hover:scale-105  flex flex-row w-max text-md lg:text-lg no-underline'>
                    <div className='lg:w-[4rem] lg:h-[4rem]   bg-white rounded-full'>
                    <AiOutlineMail className='text-[#f96666] group-hover:text-white text-2xl lg:text-[2rem] m-3'/>
                    </div> 
                    <span className='text-center text-white m-auto px-8 no-underline  lg:text-2xl'>email us now</span>
                </a>
            </div>
            <img src={bottomImg} className='w-full' alt="" />
        </div>
    </div>
  )
}

export default DogSection
