import React, { useEffect } from 'react'
import aboutImg from '../assets/about.jpg'
import DogBg from '../assets/dog-bg.jpg'
import {AiOutlineMail} from 'react-icons/ai'



const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='bg-[#DDDDDD]'>   
        <img src={aboutImg}  className='w-full h-[100vh] brightness-[86%] object-cover' alt="image" />
        <div 
        data-aos='fade-down'
        data-aos-delay='200'
        className='absolute text-center top-[45%] lg:top-[42%] left-[36%] lg:left-[42%] 2xl:left-[45%] dancing lg:w-fit -translate-x-1/2'>
            <p className='lg:text-[4rem] text-white underline-offset-8 decoration-4'>About us.</p>
        </div>
        <div>
            <div 
            data-aos='fade-up'
            data-aos-delay='200'
            >
                <p className='dancing mt-4 text-red-400 text-center'>About us</p>
                <h2 className='text-center px-4 text-[#248275] font-bold text-[3rem]'>Who We Are.</h2>
            </div>
            <div 
            data-aos='fade-down'
            data-aos-delay='200'
            className='py-4 m-auto w-2/3 text-[#52665b]'>
                <p>We are a local family owned business with years of experience doing our part to keep Brandon clean. Providing quality and affordable cleaning services in Brandon and surrounding areas.</p>
                <p>Contact us for a custom estimate. No matter what you need to have cleaned, Maria & Stan Cleaning Services can get it done</p>
                <p>We can quickly arrive at your home for a one-time cleaning. You can also discuss and schedule Cleaning Services or Maid Services on an ongoing basis – always at reasonable prices. Maria & Stan Cleaning Service.</p>
                <p>We work with homeowners, condominium owners, apartment owners and tenants, property managers, real estate professionals, business owners, commercial real estate owners and managers, home builders and residential and commercial construction crews.</p>
            </div>
        </div>
        <div className='relative'>
                        <img src={DogBg} className='h-[135vh] lg:h-[170vh] xl:h-[100vh] z-[-10000] object-cover' alt="" />
                        <div 
                        data-aos='fade-up'
                        data-aos-delay='200'
                        className='absolute text-left top-12 lg:top-[10%] px-8 lg:px-36 w-[70%]  lg:h-[100%]'>
                            <div className=''>
                            <h4 className='dancing text-white lg:w-3/4'>Maria & Stan Cleaning</h4>
                            <h2 className='text-white text-[3rem] w-[130%] lg:text-[6rem] font-bold'>Your Source For Expert Cleaning Services</h2>
                            </div>
                            <div className='bg-white lg:w-1/2 py-4 rounded-lg mb-4'>
                                <div className='px-4'>
                                    <p className='text-sm md:text-lg'>Trust in us for reliable, punctual home cleaning services. Meeting your needs is always the number one priority of every one of our cleaners. We provide all cleaning supplies and equipment.</p>
                                </div>
                            </div>
                            <a href='mailto:mariaandstancleaning@gmail.com'className='rounded-[120px] py-2 lg:py-[15px] capitalize px-2 bg-red-400 hover:scale-105  flex flex-row w-max text-md lg:text-lg no-underline'>
                                <div className='lg:w-[4rem] lg:h-[4rem]   bg-white rounded-full'>
                                <AiOutlineMail className='text-[#f96666] group-hover:text-white text-2xl lg:text-[2rem] m-3'/>
                                </div> 
                                <span className='text-center text-white m-auto px-8 no-underline  lg:text-2xl'>email us now</span>
                            </a>
                        </div>
                    </div>

    </div>
  )
}

export default About