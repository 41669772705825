// import hero slider images
import HeroSlideImage1 from './assets/slide_1.jpg';
import HeroSlideImage2 from './assets/slide_2.jpg';
import HeroSlideImage3 from './assets/slide_3.jpg';

// icons

import {ImLocation} from 'react-icons/im'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFacebook} from 'react-icons/bs'





export const heroSlider = [
    {
      id: 1,
      image: <HeroSlideImage1 />,
      title:
        "There's nothing like a Clean Home",
    },
    {
      id: 2,
      image: <HeroSlideImage2 />,
      title:
        'We Take care of your home',
    },
    {
      id: 3,
      image: <HeroSlideImage3 />,
      title:
        'Enjoy a better and cleaner Home',
    },
  ];

export const introInfo = [
  {
    id: 1,
    image: require('./assets/booking.png'),
    title: 'Book',
    subtitle: 'Get in touch and book your estimate'
  },
  {
    id: 2,
    image: require('./assets/clean-house.png'),
    title: 'clean',
    subtitle: 'A professional comes and cleans your place'
  },
  {
    id: 3,
    image: require('./assets/relax.png'),
    title: 'relax',
    subtitle: 'Sit back and enjoy your clean home'
  },
];

export const servicesSlider = [
{
  id:1,
  image: require('./assets/janitor.jpg'),
  title: 'Janitorial Service',
},
{
  id: 2,
  image: require('./assets/carpet-cleaning.jpeg'),
  title: 'Carpet Cleaning',
},
{
  id: 3,
  image: require('./assets/construction.png'),
  title: 'Construction Sites',
},
{
  id: 4,
  image: require('./assets/window.jpg'),
  title: 'Window Cleaning',
},
{
  id: 5,
  image: require('./assets/rental.jpg'),
  title: 'Property Management',
},
{
  id: 6,
  image: require('./assets/moving.jpg'),
  title: 'Moving in/out',
},

]

export const welcomeInfo = [
  {
    id: 1,
    name: 'Welcome!',
    image: require('./assets/cleaner.png'),
    title: 'Professional Cleaning Services',
    paragraph: 'Maria & Stan Cleaning has been cleaning homes for years in Brandon and surrounding areas. Our cleaning prices are honest, fair, and reasonable. We provide professional cleaning services that will give your home a fresh new look and feel that you deserve, especially after a long day of work.',
    paragraph2: 'Maria & Stan Cleaning is a trusted, fully-licensed and insured cleaning company based in Brandon, Manitoba.'
  },
];

export const payImage = [
  {
    id: 1,
    image: require('./assets/visa.png'),
  },
  {
    id: 2,
    image: require('./assets/mastercard.png'),
  },
  {
    id: 3,
    image: require('./assets/paypal.png'),
  },
  {
    id: 4,
    image: require('./assets/bitcoin.png'),
  },
]

export const contactInfo = [
  {
    id: 1,
    title: 'Address:',
    subtitle: 'Brandon, Manitoba, Canada.',
    icon: <ImLocation />
  },
  {
    id: 2,
    title: 'Email:',
    href: 'mailto:mariaandstancleaning@gmail.com',
    subtitle: 'mariaandstancleaning@gmail.com',
    icon: <HiOutlineMail />
  },
  {
    id: 3,
    title: 'Facebook',
    href: 'https://www.facebook.com/M-S-Cleaning-Service-105570221615351/',
    subtitle: 'Follow us on Facebook',
    icon: <BsFacebook />
  },
]

export const housePage = [
  {
    id: 1,
    image: require('./assets/slide_1.jpg'),
    title: 'title',
    name: 'name'
  },
]

export const links = [
  {
    id: 1,
    link: "house",
    title: "House Cleaning"
  },
  {
    id: 2,
    link: "commercial",
    title: "Commercial"
  },
  {
    id: 3,
    link: "construction",
    title: "Construction"
  },
  {
    id: 4,
    link: "about",
    title: "About"
  },
  {
    id: 5,
    link: "contact",
    title: "Contact"
  },
];

export const quickLinks = [
  {
    id: 1,
    name: 'About us',
    url: "about"
  },
  {
    id: 2,
    name: 'Contact us',
    url: "contact"
  },
  {
    id: 1,
    name: 'Construction',
    url: "construction"
  },
  {
    id: 1,
    name: 'Commercial',
    url: "commercial"
  },
  {
    id: 1,
    name: 'House cleaning',
    url: "house"
  },
]