import React, { useEffect, useState } from 'react'
import {housePage} from '../data'
import Services from '../components/Services'
import DogBg from '../assets/dog-bg.jpg'
import {servicesSlider,} from '../data';
import {AiOutlineMail} from 'react-icons/ai'
import footerTop from '../assets/footer-top.png'




export default function House() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
            <>
                <div className='w-full text-center m-auto overflow-hidden' >
                    {housePage.map((page, index) => {
                        const {image, name, title} = page;
                        return (
                            <div>
                                <div>
                                    <img src={image}  className='w-full h-[100vh] brightness-90 object-cover' alt="image" />
                                    <div
                                        data-aos='fade-down'
                                        data-aos-delay='100'
                                        className='absolute text-center top-[48%] lg:top-[42%] left-[30%] lg:left-[39%] 2xl:left-[45%] dancing lg:w-fit -translate-x-1/2'>
                                        <p className='lg:text-[4rem]  text-white'>House Cleaning</p>
                                    </div>
                                </div>
                                <div className='py-12 bg-[#e5f6f5]'>
                                <p
                                data-aos='fade-down'
                                data-aos-delay='200'
                                className='dancing mt-4 text-red-400 text-center'>Services</p>
                                <h2     data-aos='fade-down'
                                        data-aos-delay='200'
                                        className='text-center px-2 text-[3rem] lg:text-[3.5rem] text-[#248275] font-bold'>Give Your Home A Deep Clean</h2>

                                {servicesSlider.map((item, index) => {
                                    const {image, title} = item;
                                    return (
                                    <div
                                        data-aos='fade-down'
                                        data-aos-delay='200'
                                     className='inline-block'>
                                        <div key={index} className='text-center py-8 px-8 cursor-pointer group pb-12 text-[18px]'>
                                        <div className='bg-white lg:w-[23rem] 2xl:w-[30rem] objet-cover h-max rounded-lg m-auto'>
                                            <div className='text-center align-middle m-auto w-full h-[12rem] 2xl:h-[15rem]'>
                                                <img src={image} alt="image" className='rounded-md bg-gradient to-black w-full h-full' />
                                            </div>
                                            <div className='py-8 capitalize tracking-wide text-[#248275] group-hover:text-[#7bdcb5] text-3xl font-bold'>{title}</div>
                                        </div>
                                        </div>
                                    </div>
                                    )
                                })}
                                </div>
                            </div>
                        )
                    })}


                    <div className='relative'>
                        <img src={DogBg} className='h-[200vh] lg:h-[170vh] xl:h-[100vh] z-[-10000] object-cover' alt="" />
                        <div
                        className='absolute text-left top-12 lg:top-[10%] px-8 lg:px-36   lg:h-[100%]'>
                            <div className=''
                            data-aos='fade-left'
                            data-aos-delay='200'
                            >
                            <h4 className='dancing text-white lg:w-3/4'>Maria & Stan Cleaning</h4>
                            <h2 className='text-white text-[2rem] lg:text-[6rem] font-bold'>Your Source For Expert Cleaning Services</h2>
                            </div>
                            <div className='bg-white lg:w-1/2 py-4 rounded-lg mb-4'>
                                <div className='px-4'
                                data-aos='fade-left'
                                data-aos-delay='100'
                                >
                                    <h4 className='text-[#248275] text-sm lg:text-lg 2xl:text-xl capitalize'>Quality House Cleaning</h4>
                                    <p className='text-sm 2xl:text-lg'>Rely on us for high-quality house cleaning that leaves every nook and cranny of your home spotless. We are proud to offer everything from new construction cleanups to laundry and housekeeping services.</p>
                                </div>
                                <div className='px-4'
                                data-aos='fade-left'
                                data-aos-delay='300'
                                >
                                    <h4 className='text-[#248275] text-sm lg:text-lg 2xl:text-xl capitalize'>Regular House Cleaning</h4>
                                    <p className='text-sm 2xl:text-lg'>Rely on us for high-quality house cleaning that leaves every nook and cranny of your home spotless. We are proud to offer everything from new construction cleanups to laundry and housekeeping services.</p>
                                </div>
                                <div className='px-4'
                                data-aos='fade-left'
                                data-aos-delay='200'
                                >
                                    <h4 className='text-[#248275] text-sm lg:text-lg 2xl:text-xl capitalize'>Occasional House Cleaning</h4>
                                    <p className='text-sm 2xl:text-lg'>Rely on us for high-quality house cleaning that leaves every nook and cranny of your home spotless. We are proud to offer everything from new construction cleanups to laundry and housekeeping services.</p>
                                </div>
                            </div>
                            <a href='mailto:mariaandstancleaning@gmail.com'className='rounded-[120px] py-2 lg:py-[15px] capitalize px-2 bg-red-400 hover:scale-105  flex flex-row w-max text-md lg:text-lg no-underline'>
                                <div className='lg:w-[4rem] lg:h-[4rem]   bg-white rounded-full'>
                                <AiOutlineMail className='text-[#f96666] group-hover:text-white text-2xl lg:text-[2rem] m-3'/>
                                </div>
                                <span className='text-center text-white m-auto px-8 no-underline  lg:text-2xl'>email us now</span>
                            </a>
                        </div>
                            </div>
                            <div className='w-full'>
                                <img src={footerTop} className='w-full' alt="" />
                            </div>
                </div>
            </>
  )
}
