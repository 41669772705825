import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import '../swiper.css'
import {Link} from 'react-router-dom';
import {Pagination, Navigation, Autoplay} from 'swiper';
import {heroSlider,} from '../data';


const HeroSlider = () => {
  return (
    <>
    <Swiper 
    className='heroSlider'
    modules={[Pagination, Navigation, Autoplay]} 
    autoplay={true}
    loop={true}
    pagination={{
      clickable: true,
      dynamicBullets: true,
    }}
    navigation={true}
    >
        {heroSlider.map((slide, index) => {
            const {title, subtitle, buttonText, image} = slide;
            return (
            <SwiperSlide key={index}>
              <div className=' bg-cover mt-16'>
                <div>
                  <div className='relative'>
                    <div 
                    data-aos='fade-down'
                    data-aos-delay='200'
                    className='absolute text-center m-auto top-[25%] lg:top-[20%] left-[5%] lg:left-[25%] lg:w-2/4'>
                      <p className='dancing text-2xl text-white'>Maria & Stan Cleaning</p>
                      <h1 className=' text-[3rem]  lg:text-[5rem] capitalize text-white'>{title}</h1>
                      <Link to='/contact'>
                        <button className='rounded-[120px] py-4 px-10 lg:py-12  lg:px-16  bg-red-400 text-white delay-100 hover:bg-[#36d0c4] w-max uppercase'>book now</button>
                      </Link>
                    </div>
                    <img className='h-[85vh] lg:h-max w-full object-cover' src={image.type} alt="" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            )
        })}
    </Swiper>
    </>
  )
}

export default HeroSlider
