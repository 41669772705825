import React, { useEffect } from 'react'
import contactImg from '../assets/contact.jpg'
import contactWave from '../assets/contact-wave.png'


const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='overflow-hidden'>
        <div>
            <img src={contactImg}  className='w-full h-[100vh] brightness-90  object-cover' alt="image" />
            <div 
            data-aos='fade-up'
            data-aos-delay='200'
            className='absolute text-center top-[45%] lg:top-[42%] left-[35%] lg:left-[40%] 2xl:left-[45%] dancing lg:w-fit -translate-x-1/2'>
                <p className='lg:text-[4rem] text-white '>Contact us</p>
            </div>
        </div>
        <div className='bg-gray-200'>
        <div className='flex flex-col lg:flex-row justify-center py-28'>
          <div 
          data-aos='fade-right'
          data-aos-delay='200'
          className='px-4'>
            <iframe className='w-[90%] m-auto lg:w-[35rem] h-[23rem] rounded-lg lg:h-[25rem]' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5147.331533601909!2d-99.95029032540893!3d49.82994679597314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52e790b2f62f5483%3A0xb0b9a56c0c5f931!2s8th%20St%2C%20Brandon%2C%20MB!5e0!3m2!1sen!2sca!4v1661285944098!5m2!1sen!2sca" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div 
          data-aos='fade-left'
          data-aos-delay='200'
          className='px-12'>
            <p className='dancing mt-4 text-red-400'>Find us</p>
            <h2 className='text-[#248275] font-bold text-[2.5rem]'>Contact information</h2>
            <div>
              <p className='text-[#52665b] flex flex-col'>Address: <span>8th St Brandon, Manitoba</span></p>
              <p className='flex flex-col'>Phone: <span className='text-red-400'>(204)721-3259</span></p>
              <p className='flex flex-col'>Email: 
              <span className=''>
              <a href='mailto:mariaandstancleaning@gmail.com' className='text-red-400 no-underline cursor-pointer'>
              mariaandstancleaning@gmail.com
              </a>
              </span></p>
            </div>
          </div>
        </div>
        <img src={contactWave} className='border-box w-max' alt="" />

        </div>
        <div className='bg-[#e5f6f5]'>
            <div 
            data-aos='fade-down'
            data-aos-delay='200'
            className=' text-center py-8'>
              <p className='dancing text-red-400'>Send Us a Message</p>
              <h2 className='text-[#248275] font-bold text-[2.5rem]'>Get in Touch</h2>
            </div>
        </div>
        <div>
        <div className="bg-[#e5f6f5] flex justify-center items-center">
          <form
          data-aos='fade-down'
          data-aos-delay='200'
            action="https://getform.io/f/1dc284ab-ad1f-40a1-9f74-7bd9a4a541c0" method='POST'
            className=" flex flex-col w-[90%] lg:w-1/3 gap-y-4"
          >
            <input
              type="text"
              name="name"
              placeholder="Your name"
              className="p-2 bg-white h-[3.85rem] border-2 rounded-lg focus:outline-none"
            />
            <input
              type="text"
              name="email"
              placeholder="Your email"
              className=" p-2 bg-white h-[3.85rem] border-2 rounded-lg focus:outline-none"
            />
            <input
              type="text"
              name="phone"
              placeholder="Your phone number"
              className=" p-2 bg-white h-[3.85rem] border-2 rounded-lg focus:outline-none"
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="10"
              className="p-2 bg-white border-2 rounded-lg focus:outline-none"
            ></textarea>

            <button className="text-[#248275] bg-[#c2f0ed] hover:bg-[#9ae7e1]  px-12 py-3 my-8 mx-auto flex items-center rounded-[120px] text-lg duration-300">
              send now
            </button>
          </form>
        </div>
        </div>
    </div>
  )
}

export default Contact