import React from 'react'
import {introInfo} from  '../data'

const Intro = () => {
  return (
    <div>
        <div 
        data-aos='fade-up'
        data-aos-delay='200'
        className='m-auto pt-2 bg-white text-center overflow-hidden z-[-10000]'>
            <h3 className='dancing text-red-400'>How does it work?</h3>
            <div className="xl:w-2/3 grid m-auto grid-cols-1 md:grid-cols-3 gap-8 text-center py-8 px-8 lg:px-0 sm:px-0">
                {introInfo.map(({id, image, title, subtitle}) => (
                    <div className='shadow-lg bg-[#F9F9F9] cursor-pointer w-full px-4 lg:w-2/3 rounded-lg items-center'>
                         <div>
                            <div className='pt-4 group gap-x-1' key={id}>
                                <img src={image} alt="image" className="w-20 group-hover:scale-105 mx-auto" />
                                <p className="smooch text-[3.5rem] text-[#248275] group-hover:text-[#36d0c4] uppercase font-[500]">{title}</p>
                                <p className="mt-[-10%] lg:text-lg xl:text-xl hidden lg:block">{subtitle}</p>
                            </div>
                         </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Intro